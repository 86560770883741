import React from 'react'
import { Link } from 'gatsby'

const PostCard = ({ posts }) => {
  return (
    <div className='container'>
      <div className='columns is-multiline is-centered'>

        {posts
          .filter(post => post.node.frontmatter.templateKey === 'article-page')
          .map(({ node: post }) => (
            <div className='column is-4' key={post.id}>
              <Link className='has-text-primary' to={post.fields.slug}>
                <div className='card is-shadowless is-floralwhite pg-2 br-18px has-height-100-percent'>

                  <div className='card-image'>
                    <figure className="image is-128x128 has-image-centered">
                      <img src={post.frontmatter.cover}></img>
                    </figure>
                  </div>

                  <div>
                      <div className='mt-1'>
                        <p className='title is-4 has-text-grey-dark has-text-centered'>{post.frontmatter.title}</p>
                        <p className='subtitle is-6 has-text-centered'><small>{post.frontmatter.date}</small></p>
                      </div>

                      <hr></hr>

                      <div className='content'>
                        {post.excerpt}
                      </div>
                    </div>
                  </div>  
              </Link>
            </div>

          ))}
      </div>
    </div>
  )


}

export default PostCard
